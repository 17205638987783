import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 830px;
  position: relative;

  @media ${device.tablet} {
    min-height: 600px;
  }
  @media ${device.desktopLG} {
    min-height: 500px;
  }
  @media ${device.desktopXL} {
    min-height: calc(100vh - 73px);
  }

  .contents {
    display: contents
  }

  .scroll-link {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    width: auto;
  }
`
