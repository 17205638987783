import React, { useState } from 'react'

import useWidth from 'src/hooks/window/useWidth'
import Help from 'inter-frontend-svgs/lib/orangeds/MD/help'

import { orange } from 'src/styles/colors'
import { Tooltip, ButtonToolTip } from './style'

export const ToolTip = () => {
  const [ open, setOpen ] = useState(false)

  const WIDTH_LG = 1024
  const windowWidth = useWidth(300)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      {windowWidth >= WIDTH_LG ? (
        <ButtonToolTip onClick={() => handleClick()}>
          <Help color={orange.extra} width={16} height={16} />
        </ButtonToolTip>
      ) : (
        <ButtonToolTip onClick={() => setOpen(true)}>
          <Help color={orange.extra} width={16} height={16} />
        </ButtonToolTip>
      )}

      {open && (
        <Tooltip className='tooltip' onClick={() => setOpen(false)}>
          <p className='fs-12 lh-15 fw-700 text-white mb-1'>
            Cashback nos Fundos de Investimento
          </p>
          <p className='fs-12 lh-15 fw-400 text-white text-left mb-0'>
            Clientes Black tem direito a 20% do rebate e clientes WIN tem
            direito a 40% do rebate como cashback, direto em suas contas.
            <br />
            <br />O cashback só é distribuído caso seja superior a R$ 10,00
          </p>
        </Tooltip>
      )}
    </>
  )
}
