import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      heroPortabilidadeDeInvestimentos: imageSharp(fluid: { originalName: { regex:"/hero-portabilidade-de-investimentos/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      aindaNaoInvestePeloInter: imageSharp(fluid: { originalName: { regex:"/ainda-nao-investe-pelo-inter/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeRendaFixa: imageSharp(fluid: { originalName: { regex: "/portabilidade-renda-fixa/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeRendaVariavel: imageSharp(fluid: { originalName: { regex: "/portabildiade-renda-variavel/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeTesouroDireto: imageSharp(fluid: { originalName: { regex:"/portabilidade-tesouro-direto/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeDeRendaVariavelEua: imageSharp(fluid: { originalName: { regex:"/portabilidade-de-renda-variavel-eua/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeDeFundos: imageSharp(fluid: { originalName: { regex:"/portabilidade-de-fundos/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
