export const dataHero = [
  {
    icon: 'no-taxes',
    label: "Sem custo para <a href='https://inter.co/pra-voce/investimentos/' target='_blank' rel='noreferrer'><span class='text-orange-extra fw-600'>investimentos nacionais</span></a>",
    hasLink: 'https://inter.co/pra-voce/investimentos/',
    linkText: 'investimentos nacionais',
  },
  {
    icon: 'cashback',
    label: 'Possibilidade de Pontos Loop nos Fundos',
    tooltip: {
      title: 'Cashback nos Fundos de Investimento',
      description: 'Clientes Black tem direito a 20% do rebate e clientes WIN tem direito a 40% do rebate como cashback, direto em suas contas.<br/><br/>O cashback só é distribuído caso seja superior a R$ 10,00',
    },
  },
  {
    icon: 'home-broker',
    label: 'Home Broker gratuito em português',
  },
  {
    icon: 'receipt',
    label: 'Seu IR em um só lugar',
  },
  {
    icon: 'card',
    label: 'Torne-se um cliente One ou Black',
  },
]
