export const tabData = [
  {
    image: 'portabilidadeRendaFixa',
    title: 'Portabilidade de Renda Fixa',
    alt: 'Pessoa no escritório navegando no smartphone consultando sobre renda fixa no Super App do Inter',
    hasButton: true,
    isVideo: false,
    listItems: [
      'Escreva <strong>“Trazer renda fixa pro Inter”</strong> na Babi e siga as instruções no chat.',
      'Você receberá o formulário de entrada de ativos de renda fixa no seu e-mail.',
      'Preencha o formulário e anexe as suas notas de corretagem. ',
      'Envie o formulário e entre em contato com a instituição de origem para solicitar a portabilidade (STVM) pro Inter.',
    ],
  },
  {
    image: 'portabilidadeDeFundos',
    title: 'Portabilidade de Fundos',
    alt: 'Pessoa desfocada no fundo com botão de player para visualização de vídeo',
    hasButton: true,
    isVideo: true,
    link: 'https://www.youtube.com/embed/5qQZ6Kh4vFE?&autoplay=1',
    listItems: [
      'Escreva <strong>“Trazer fundos pro Inter”</strong> na Babi e siga as instruções no chat.',
      'Você receberá a carta de portabilidade de fundos no seu e-mail.',
      'Preencha a carta com os dados da instituição de origem e os fundos que deseja trazer pro Inter.',
      'Envie a carta assinada para a instituição de origem.',
    ],
  },
  {
    image: 'portabilidadeRendaVariavel',
    title: 'Portabilidade de Renda Variável Brasil',
    alt: 'Pessoa de óculos com camiseta preta pesquisando no smartphone sobre portabilidade e renda variável',
    hasButton: false,
    isVideo: false,
    listItems: [
      'Solicite o formulário <strong>STVM</strong> na instituição de origem.',
      'Preencha a área de <strong>Identificação de Cedente</strong> com os dados da instituição de origem.',
      'Na <strong>Identificação do Cessionário</strong>, informe seus dados no Inter.',
      'Envie a carta assinada para a instituição de origem.',
    ],
  },
  {
    image: 'portabilidadeDeRendaVariavelEua',
    title: 'Portabilidade de Renda Variável EUA',
    alt: 'Pessoa sorrindo no fundo desfocado com botão de player para visualização de vídeo.',
    hasButton: true,
    isVideo: true,
    link: 'https://www.youtube.com/embed/97-UfClHBT8?&autoplay=1',
    listItems: [
      'Escreva <strong>“Trazer ações pra Apex”</strong> na Babi e siga as instruções no chat.',
      'Você receberá no seu e-mail o formulário ACAT.',
      'Preencha com os dados da sua conta Apex, os dados da instituição de origem e os ativos que deseja trazer pro Inter.',
      'Envie o formulário assinado para a Apex no e-mail cs@apexfintechsolutions.com',
    ],
  },
  {
    image: 'portabilidadeTesouroDireto',
    title: 'Portabilidade de Tesouro Direto',
    alt: 'Pessoa com camiseta laranja e com smartphone fazendo a portabilidade do tesouro direto',
    hasButton: false,
    isVideo: false,
    listItems: [
      'Solicite o formulário <strong>STVM</strong> na instituição de origem.',
      'Preencha a área de <strong>Identificação de Cedente</strong> com os dados da instituição de origem.',
      'Na <strong>Identificação do Cessionário</strong>, informe seus dados no Inter.',
      'Em seguida, informe os ativos que quer transferir pro Inter.',
      'Envie o formulário assinado para a sua instituição de origem.',
    ],
  },
]

export const tabTitles = [ 'Renda Fixa', 'Fundos', 'Renda Variável Brasil', 'Renda Variável EUA', 'Tesouro Direto' ]
