import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import ScrollTo from 'src/components/ScrollTo'
import { orange } from 'src/styles/colors'
import { ToolTip } from './Tooltip/_Tooltip'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { dataHero } from '../../assets/data/_dataHero'
import Home from '@interco/icons/build-v4/orangeds/MD/home'
import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/styles/breakpoints'
import usePageQuery from '../../pageQuery'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import headerJson from '../../assets/data/header.json'
import { Container } from './styles'

interface IDataHero {
  icon: string;
  label: string;
  hasLink?: string;
  linkText?: string;
  tooltip?: {
    title: string;
    description: string;
  };
}

const Hero = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <Container className='py-4'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5'>
            <div className='bread'>
              <Link
                to='/'
                className='d-md-inline'
              ><Home height={24} width={24} color='#6A6E81' />
              </Link>
              <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
              <Link
                to='/pra-voce/investimentos/'
                className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
              >
                {headerJson.breadcrumb[0]}
              </Link>
              <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
              <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
            </div>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mt-3 mb-4'>
              Seus investimentos rendem mais no Inter
            </h1>
            {dataHero.map((item: IDataHero) => (
              <div className='d-flex align-items-center mb-3' key={item.label}>
                <div>
                  <OrangeIcon icon={item.icon} size='MD' color='#000' />
                </div>
                {item.hasLink ? (
                  <span
                    onClick={() =>
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Seus investimentos rendem mais no Inter',
                        element_action: 'click button',
                        element_name: item.linkText ? item.linkText : 'null',
                        redirect_url: item.hasLink,
                      })
                    }
                  >
                    <p
                      className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 fw-700 text-grayscale--500 mb-0 ml-2 mr-5 mr-xl-0'
                      dangerouslySetInnerHTML={{ __html: item.label }}
                    />
                  </span>
                ) : (
                  <div className='d-flex'>
                    {item.tooltip?.title ? (
                      <div className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 fw-700 text-grayscale--500 mb-0 ml-2'>
                        <span dangerouslySetInnerHTML={{ __html: item.label }} />
                        <span className='cursor-pointer tooltip--svg ml-2 ml-lg-0 position-relative'>
                          <ToolTip />
                        </span>
                      </div>
                    ) : (
                      <p
                        className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 fw-700 text-grayscale--500 mb-0 ml-2'
                        dangerouslySetInnerHTML={{ __html: item.label }}
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 order-first order-md-last'>
            <Img
              fluid={data.heroPortabilidadeDeInvestimentos.fluid}
              alt='Pessoa com camisa laranja sorrindo acompanhando os investimentos no Super App do Inter'
              className='float-lg-right'
            />
          </div>
        </div>
      </div>
      {
        width >= WIDTH_MD &&
          <ScrollTo
            to='#tabs'
            section='dobra_01'
            sectionName='Seus investimentos rendem mais no Inter'
            elementName='Faça a sua portabilidade'
            styles='scroll-link '
          >
          <p className='text-orange--extra mb-0 fs-14 fs-lg-16 fw-600'>
            Faça a sua portabilidade
          </p>
          <OrangeIcon icon='chevron-down' size='MD' color={orange.extra} />
      </ScrollTo>
      }
    </Container>
  )
}

export default Hero
