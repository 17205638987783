import React, { ReactNode, useState } from 'react'
import { Container, UL, LI, Button, SlideBar, BottomBar } from './style'

type TabProps = {
  classDiv?: string;
  items: string[];
  children: ReactNode[];
  setTabActive?: Function;
  styles?: string;
  color: string;
  activeColor?: string;
  border?: boolean;
  slideBar?: boolean;
}

const Tab = ({ classDiv, items, children, setTabActive, styles, color, activeColor, border, slideBar }: TabProps) => {
  const [ activeItem, setActiveItem ] = useState(0)

  function handleClick (index: number) {
    setActiveItem(index)
    if (setTabActive) {
      setTabActive(index)
    }
  }

  return (
    <Container className={classDiv}>
      <nav>
        <UL className='pl-0 d-flex'>
          {items?.map((item: string, index: number) => (
            <LI activeItem={activeItem === index} key={item} activeColor={activeColor} border={border}>
              <Button className={styles} color={color} onClick={() => handleClick(index)}>
                {item}
              </Button>
              {slideBar && <SlideBar />}
            </LI>
          ))}
        </UL>
      </nav>
      <div className='mt-3'>{
        children?.map((item: ReactNode, index: number) => (
          <div className={`${activeItem === index ? 'd-block' : 'd-none'}`} key={index}>{item}</div>
        ))
      }
      </div>
      <BottomBar />
    </Container>
  )
}

Tab.defaultProps = {
  styles: 'fs-16 fs-lg-24 lh-20 lh-lg-30',
  color: '#6A6C72',
}

export default Tab
