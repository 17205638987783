import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
/* display: flex;
align-items: center;
justify-content: center; */
  min-height: 610px;

  @media ${device.tablet} {
    min-height: 616px;
  }

  @media ${device.desktopLG} {
    /* min-height: 726px; */
  }

  @media ${device.desktopXL} {
    min-height: 690px;
  }

  ul {
    /* border-bottom: 1px solid #161616; */
    justify-content: space-between;
    overflow: scroll;
    margin-bottom: 50px;

    ::-webkit-scrollbar {
        display: none;
    }
    
    li {
      white-space: nowrap;
      width: 100%;
    }
  }

  .btn {
    min-width: 100% !important;
  }
`

export const ItemNumber = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: ${grayscale[500]};
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

`
