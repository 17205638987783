import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
`

export const Title = styled.h3`
  display: block;
  font-family: 'Sora';
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};
`
