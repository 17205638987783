import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Container = styled.div`
  width: auto;
`

export const UL = styled.ul`
  list-style: none;
`

export const BottomBar = styled.div`
  width: 100%;
  height: 1px;
  position: absolute;
  z-index: 0;
  background: #1e1e1e;
  top: 36px;
`

type LiProps = {
  activeItem: boolean;
  activeColor?: string;
  border?: boolean;
}

type ButtonProps = {
  color: string;
}

export const LI = styled.li<LiProps>`
  position: relative;
  margin: 0 10px;

  @media ${device.tablet} {
    margin: 0 20px;
  }

  @media ${device.desktopLG} {
    margin: 0 50px;
  }

  &:first-child {
    hr {
      border-radius: 25px 0 0 0;
    }
  }

  &:last-child  {
    hr {
      border-radius: 0 25px 0 0;
    }
  }

  Button {
    position: relative;
    z-index: 1;
    ${(props: LiProps) => props.activeItem && `
      color: ${props.activeColor || orange.extra};
      ${!props.border ? `border-bottom: 4px solid ${props.activeColor || orange.extra};` : '' }
      &::after {
        content: "";
        ${props.border ? 'display: block;' : '' }
        ${props.border ? 'height: 4px;' : '' }
        ${props.border ? 'width: 100%;' : '' }
        ${props.border ? 'border-radius: 25px 25px 0 0;' : '' }
        ${props.border ? `background: ${props.activeColor};` : '' }
        ${props.border ? 'margin-top: 10px;' : '' }
      }
    `}
  }
`

export const Button = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  font-weight: 600;
  text-align: left;
  width: auto;
  color: ${(props: ButtonProps) => props.color};

  &:focus {
    outline: 0px;
  }
`

export const SlideBar = styled.hr`
  border: none;
  position: absolute;
  z-index: 0;
  height: 4px;
  background: ${grayscale[100]};
  top: 17px;
  width: 100%;
`
