import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface IAindaNaoInvesteProps {
  openModal: (data: boolean) => void;
  setDataLayer: Function;
}

const AindaNaoInveste = ({ openModal, setDataLayer }: IAindaNaoInvesteProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const dataLayer: IDataLayerParams = {
    section: 'dobra_03',
    section_name: 'Ainda não investe pelo Inter?',
    element_action: 'click button',
    element_name: 'Abra sua conta',
  }

  const handleOpenModal = () => {
    openModal(true)
  }

  return (
    <div className='py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4'>Ainda não investe pelo Inter?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4'>Abra sua conta agora mesmo pra trazer seus investimentos, começar a investir pelo Inter, e ter os melhores benefícios na melhor plataforma.</p>
            <button
              className='btn btn--lg btn-orange--extra text-white d-none d-md-block text-none'
              onClick={() => {
                handleOpenModal()
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
            >Abra sua conta
            </button>
            <a
              href='bancointer://centralAjuda/interchat'
              className='btn btn--lg btn-orange--extra text-white d-md-none text-none'
              onClick={() =>
                sendDatalayerEvent({
                  section: 'dobra_03',
                  section_name: 'Ainda não investe pelo Inter?',
                  element_action: 'click button',
                  element_name: 'Abra sua conta',
                  redirect_url: 'bancointer://centralAjuda/interchat',
              })}
            >
              Abra sua conta
            </a>
          </div>
          <div className='col-12 col-md-6 col-lg-5 d-none d-md-block'>
            <Img fluid={data.aindaNaoInvestePeloInter.fluid} alt='Pessoa simpática com blusa laranja abrindo a conta no Super App do Inter para investir' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AindaNaoInveste
