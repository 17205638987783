import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import OpenVideo from 'src/components/OpenVideo'
import { Container, ItemNumber } from './styles'
import Tab from '../../components/Tab/_index'
import { tabData, tabTitles } from '../../assets/data/_tabsJson'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface ITabsProps {
  setOpenModal: (data: boolean) => void;
  setDataLayer: Function;
}

interface ITabData {
  image: string;
  title: string;
  alt: string;
  hasButton: boolean;
  isVideo: boolean;
  link?: string;
  listItems: string[];
}

const Tabs = ({ setOpenModal, setDataLayer }: ITabsProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const dataLayer: IDataLayerParams = {
    section: 'dobra_02',
    section_name: 'null',
    element_action: 'click button',
    element_name: '',
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  return (
    <Container className='bg-grayscale--100 pt-5 pb-5' id='tabs'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Tab
              items={tabTitles}
              border={true}
              color='#161616'
              activeColor='#FF7A00'
              styles='fs-14 lh-17 text-grayscale--500'
            >
              {tabData.map((item: ITabData, index: number) => (
                <div key={index} className='h-100 row align-items-center justify-content-between mb-3'>
                  <div className='col-12 col-md-6 d-none d-md-block'>
                    {item.isVideo ? (
                      <OpenVideo
                        link={item.link}
                        styles='cursor-pointer'
                        onClick={() =>
                          sendDatalayerEvent({
                            section: 'dobra_02',
                            section_name: 'null',
                            element_action: 'click video',
                            element_name: `Assista ao video + ${item.title}`,
                            redirect_url: item.link,
                        })}
                      >
                        <Img fluid={data[item.image].fluid} alt={item.alt} />
                      </OpenVideo>
                    ) : (
                      <Img fluid={data[item.image].fluid} alt={item.alt} />
                    )}
                  </div>
                  <div className='col-12 col-md-6 col-xl-5'>
                    <h3 className='text-grayscale--500 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-4'>{item.title}</h3>
                    {item.listItems.map((subitem: string, index: number) => (
                      <div key={index} className='d-flex align-items-center mb-4'>
                        <div>
                          <ItemNumber>{index + 1}</ItemNumber>
                        </div>
                        <p className='ml-4 mb-0 fs-14 lh-17 fs-lg-16 lh-lg-17 fs-xl-18 lh-xl-22 text-grayscale--400' dangerouslySetInnerHTML={{ __html: subitem }} />
                      </div>
                    ))}
                    {item.hasButton && (
                      <div>
                        <a
                          href='https://intergo.app/6fed8365'
                          className='d-md-none btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mt-4 text-md-center d-flex text-none'
                          onClick={() =>
                            sendDatalayerEvent({
                              section: 'dobra_02',
                              section_name: 'null',
                              element_action: 'click button',
                              element_name: `Solicitar portabilidade + ${item.title}`,
                              redirect_url: 'https://intergo.app/6fed8365',
                          })}
                        >
                          Solicitar portabilidade
                        </a>
                        <button
                          className='w-100 btn btn--lg btn-orange--extra text-white d-none d-md-block text-none'
                          onClick={() => {
                            handleOpenModal()
                            sendDatalayerEvent({
                              ...dataLayer,
                              element_name: `Solicitar portabilidade + ${item.title}`,
                            })
                            setDataLayer({
                              ...dataLayer,
                              element_name: `Solicitar portabilidade + ${item.title}`,
                            })
                          }}
                        >
                          Solicitar portabilidade
                        </button>
                      </div>
                    )}
                  </div>
                </div>
          ))}
            </Tab>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Tabs
